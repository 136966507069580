<template>
  <header class="flex items-center justify-center w-full h-50vh bg-gray-400">
    <h1 class="text-3xl font-medium tracking-wide text-center text-white md:w-1/2 mt-32">
      <span class="block m-2 text-4xl uppercase md:text-6xl">
        {{ $t('News.header.h1') }}
      </span>
    </h1>
  </header>
  <div class="flex items-center justify-center my-16">
    <div class="flex items-center justify-center gap-8 flex-wrap">
      <button
        @click="setList('all')"
        class="px-8 py-2 mx-5 text-white bg-black outline-none rounded-xl"
      >
        {{ $t('News.All') }}
      </button>
      <button
        @click="setList('news')"
        class="px-8 py-2 mx-5 text-white outline-none rounded-xl bg-lightBlue"
      >
        {{ $t('News.News') }}
      </button>
      <button
        @click="setList('Advertising')"
        class="px-8 py-2 mx-5 text-white outline-none rounded-xl bg-primary"
      >
        {{ $t('News.Advertising') }}
      </button>
    </div>
  </div>
  <div
    v-if="list"
    class="container relative grid grid-cols-1 gap-12 p-8 mx-auto my-24 bg-white rounded-lg sm:grid-cols-2 lg:grid-cols-3 md:gap-12"
  >
    <div
      v-for="(item, index) in list"
      :key="index"
      class="mb-12 -mt-12 overflow-hidden transition-all duration-300 bg-white border border-gray-100 rounded-2xl shadow-primary hover:shadow-md hover:bg-gray-50"
    >
      <img
        :src="URL + item.image"
        alt=""
        loading="lazy"
        class="object-contain w-full h-44"
      />
      <div class="p-5">
        <h4 class="flex justify-between font-medium">
          <span class="text-2xl text-dark">
            {{ $t('lang') == 'ar' ? item.title_ar : item.title_en }}
          </span>
          <span class="text-primary">
            {{ item.type }}
          </span>
        </h4>
        <span class="block w-full h-1 my-3 rounded-md bg-primary"></span>
        <p class="my-3 text-lg text-black line-clamp">
          {{ $t('lang') == 'ar' ? item.content_ar : item.content_en }}
        </p>
        <button
          @click="
            () => {
              $store.commit(`setSpecificPost`, item)
              $router.push(`/NewsDetails`)
            }
          "
          class="flex items-center mt-3 font-medium focus:outline-none text-primary"
        >
          <span>{{ $t('Home.Read-more') }} </span>
          <img
            class="mx-2 transform"
            :class="$t('lang') == 'ar' && 'rotate-180'"
            src="../assets/Arrow2.svg"
            alt=""
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'posts',
  data() {
    return {
      URL: 'https://api.nowarat.ly/',
      list: null,
    }
  },
  methods: {
    setList(type) {
      if (type == 'news') {
        this.list = this.news
      } else if (type == 'Advertising') {
        this.list = this.Advertising
      } else {
        this.list = this.all
      }
    },
  },
  mounted() {
    this.setList('all')
  },
  computed: {
    news() {
      return this.$store.getters.news || []
    },
    Advertising() {
      return this.$store.getters.Advertising || []
    },
    all() {
      const advertising = this.$store.getters.Advertising || []
      const news = this.$store.getters.news || []
      return [...advertising, ...news]
    },
    // locale() {
    //   return this.$i18n.locale
    // },
  },
}
</script>
<style lang="scss" scoped>
header {
  background: url(../assets/female-hand-with-megaphone-yellow-background.jpg);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-line-clamp: 3;
  -o-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;

  /* Needed to make it work */
}
</style>
